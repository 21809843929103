define("discourse/plugins/chat/discourse/templates/connectors/chat-composer-inline-buttons/emoji-picker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.desktopView}}
    <EmojiPicker
      @didSelectEmoji={{@outletArgs.composer.onSelectEmoji}}
      @btnClass="chat-composer-button btn-transparent -emoji"
      @context="chat"
    />
  
    <Chat::Composer::Separator />
  {{/if}}
  */
  {
    "id": "7auUbP5i",
    "block": "[[[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@didSelectEmoji\",\"@btnClass\",\"@context\"],[[30,1,[\"composer\",\"onSelectEmoji\"]],\"chat-composer-button btn-transparent -emoji\",\"chat\"]],null],[1,\"\\n\\n  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"emoji-picker\",\"chat/composer/separator\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/connectors/chat-composer-inline-buttons/emoji-picker.hbs",
    "isStrictMode": false
  });
});