define("discourse/plugins/chat/discourse/components/chat-composer-dropdown", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-i18n", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _dButton, _concatClass, _discourseI18n, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatComposerDropdown extends _component.default {
    async onButtonClick(button, closeFn) {
      await closeFn();
      button.action();
    }
    static #_ = (() => dt7948.n(this.prototype, "onButtonClick", [_object.action]))();
    doubleClick(event) {
      event.preventDefault();
      const uploadButton = this.args.buttons.filter(button => button.id === "chat-upload-btn" && !button.disabled)[0];
      uploadButton?.action?.();
    }
    static #_2 = (() => dt7948.n(this.prototype, "doubleClick", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @buttons.length}}
          <DMenu
            class={{concatClass
              "chat-composer-dropdown__trigger-btn"
              "btn-flat"
              (if @hasActivePanel "has-active-panel")
            }}
            @title={{i18n "chat.composer.toggle_toolbar"}}
            @icon="plus"
            @disabled={{@isDisabled}}
            @arrow={{true}}
            @placements={{array "top" "bottom"}}
            @identifier="chat-composer-dropdown__menu"
            @modalForMobile={{true}}
            {{on "dblclick" this.doubleClick}}
            ...attributes
            as |menu|
          >
            <ul class="chat-composer-dropdown__list">
              {{#each @buttons as |button|}}
                <li class={{concatClass "chat-composer-dropdown__item" button.id}}>
                  <DButton
                    @icon={{button.icon}}
                    @action={{fn this.onButtonClick button menu.close}}
                    @label={{button.label}}
                    class={{concatClass
                      "chat-composer-dropdown__action-btn"
                      "btn-transparent"
                      button.id
                    }}
                  />
                </li>
              {{/each}}
            </ul>
          </DMenu>
        {{/if}}
      
    */
    {
      "id": "4ZIXTjGp",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[28,[32,1],[\"chat-composer-dropdown__trigger-btn\",\"btn-flat\",[52,[30,2],\"has-active-panel\"]],null]],[17,3],[4,[32,4],[\"dblclick\",[30,0,[\"doubleClick\"]]],null]],[[\"@title\",\"@icon\",\"@disabled\",\"@arrow\",\"@placements\",\"@identifier\",\"@modalForMobile\"],[[28,[32,2],[\"chat.composer.toggle_toolbar\"],null],\"plus\",[30,4],true,[28,[32,3],[\"top\",\"bottom\"],null],\"chat-composer-dropdown__menu\",true]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"ul\"],[14,0,\"chat-composer-dropdown__list\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,0,[28,[32,1],[\"chat-composer-dropdown__item\",[30,6,[\"id\"]]],null]],[12],[1,\"\\n              \"],[8,[32,5],[[16,0,[28,[32,1],[\"chat-composer-dropdown__action-btn\",\"btn-transparent\",[30,6,[\"id\"]]],null]]],[[\"@icon\",\"@action\",\"@label\"],[[30,6,[\"icon\"]],[28,[32,6],[[30,0,[\"onButtonClick\"]],[30,6],[30,5,[\"close\"]]],null],[30,6,[\"label\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[6]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[5]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@buttons\",\"@hasActivePanel\",\"&attrs\",\"@isDisabled\",\"menu\",\"button\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-composer-dropdown.js",
      "scope": () => [_dMenu.default, _concatClass.default, _discourseI18n.i18n, _helper.array, _modifier.on, _dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatComposerDropdown;
});