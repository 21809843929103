define("discourse/plugins/chat/discourse/lib/lightbox", ["exports", "jquery", "discourse/helpers/loading-spinner", "discourse/lib/load-script", "discourse-i18n"], function (_exports, _jquery, _loadingSpinner, _loadScript, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lightbox;
  function lightbox(images) {
    (0, _loadScript.default)("/javascripts/jquery.magnific-popup.min.js").then(function () {
      (0, _jquery.default)(images).magnificPopup({
        type: "image",
        closeOnContentClick: false,
        mainClass: "mfp-zoom-in",
        tClose: (0, _discourseI18n.i18n)("lightbox.close"),
        tLoading: _loadingSpinner.spinnerHTML,
        image: {
          verticalFit: true
        },
        gallery: {
          enabled: true
        },
        callbacks: {
          elementParse: item => {
            item.src = item.el[0].dataset.largeSrc || item.el[0].src;
          }
        }
      });
    });
  }
});