define("discourse/plugins/chat/discourse/initializers/chat-decorators", ["exports", "discourse/instance-initializers/onebox-decorators", "discourse/lib/get-url", "discourse/lib/hashtag-decorator", "discourse/lib/highlight-syntax", "discourse/lib/plugin-api", "discourse/lib/url", "discourse-i18n", "discourse/plugins/chat/discourse/lib/lightbox"], function (_exports, _oneboxDecorators, _getUrl, _hashtagDecorator, _highlightSyntax, _pluginApi, _url, _discourseI18n, _lightbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "chat-decorators",
    initializeWithPluginApi(api, container) {
      const siteSettings = container.lookup("service:site-settings");
      const site = container.lookup("service:site");
      api.decorateChatMessage(element => (0, _oneboxDecorators.decorateGithubOneboxBody)(element), {
        id: "onebox-github-body"
      });
      api.decorateChatMessage(element => {
        element.querySelectorAll(".onebox.githubblob li.selected").forEach(line => {
          const scrollingElement = this._getScrollParent(line, "onebox");

          // most likely a very small file which doesn’t need scrolling
          if (!scrollingElement) {
            return;
          }
          const scrollBarWidth = scrollingElement.offsetHeight - scrollingElement.clientHeight;
          scrollingElement.scroll({
            top: line.offsetTop + scrollBarWidth - scrollingElement.offsetHeight / 2 + line.offsetHeight / 2
          });
        });
      }, {
        id: "onebox-github-scrolling"
      });
      api.decorateChatMessage(element => (0, _highlightSyntax.default)(element, siteSettings, container.lookup("service:session")), {
        id: "highlightSyntax"
      });
      api.decorateChatMessage(this.renderChatTranscriptDates, {
        id: "transcriptDates"
      });
      api.decorateChatMessage(this.forceLinksToOpenNewTab, {
        id: "linksNewTab"
      });
      api.decorateChatMessage(element => (0, _lightbox.default)(element.querySelectorAll("img:not(.emoji, .avatar)")), {
        id: "lightbox"
      });
      api.decorateChatMessage(element => (0, _hashtagDecorator.decorateHashtags)(element, site), {
        id: "hashtagIcons"
      });
    },
    _getScrollParent(node, maxParentSelector) {
      if (node === null || node.classList.contains(maxParentSelector)) {
        return null;
      }
      if (node.scrollHeight > node.clientHeight) {
        return node;
      } else {
        return this._getScrollParent(node.parentNode, maxParentSelector);
      }
    },
    renderChatTranscriptDates(element) {
      element.querySelectorAll(".chat-transcript").forEach(transcriptEl => {
        const dateTimeRaw = transcriptEl.dataset["datetime"];
        const dateTimeLinkEl = transcriptEl.querySelector(".chat-transcript-datetime a");

        // we only show date for first message
        if (!dateTimeLinkEl) {
          return;
        }
        if (dateTimeLinkEl.innerText !== "") {
          // same as highlight, no need to do this for every single message every time
          // any message changes
          return;
        }
        dateTimeLinkEl.innerText = moment(dateTimeRaw).format((0, _discourseI18n.i18n)("dates.long_no_year"));
      });
    },
    forceLinksToOpenNewTab(element) {
      const links = element.querySelectorAll(".chat-message-text a:not([target='_blank'])");
      for (let linkIndex = 0; linkIndex < links.length; linkIndex++) {
        const link = links[linkIndex];
        if (!_url.default.isInternal(link.href) || !(0, _getUrl.samePrefix)(link.href)) {
          link.setAttribute("target", "_blank");
        }
      }
    },
    initialize(container) {
      if (container.lookup("service:chat").userCanChat) {
        (0, _pluginApi.withPluginApi)("0.8.42", api => this.initializeWithPluginApi(api, container));
      }
    }
  };
});